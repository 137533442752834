import { useState, useEffect } from 'react';
import { Modal, Button } from 'bv-components';
import { t } from 'bv-i18n';
import { apiFetchGamblingSupportData } from '../api';

const SelfExclusionSuccessModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [defaultProvider, setDefaultProvider] = useState(null);

  useEffect(() => {
    apiFetchGamblingSupportData().then((data) => {
      const defaultGamblingSupportProvider = data.gamblingSupport.find(
        (provider) => provider.default,
      );

      setDefaultProvider(defaultGamblingSupportProvider);
      setShowModal(true);
    });
  }, []);

  if (!showModal) return null;

  return (
    <Modal
      success
      icon
      title={t('javascript.self_exclusion.success_submit_modal.request_was_successful')}
      iconClosable
      onCloseClick={() => setShowModal(false)}
    >
      {defaultProvider && (
        <Button
          inverse
          primary
          className="self-exclusion-form__success-button"
          onClick={() => {
            window.open(defaultProvider.link);
          }}
        >
          <span>{t('javascript.self_exclusion.success_submit_modal.visit')}</span>
          <img src={defaultProvider.logo} alt={defaultProvider.name} />
        </Button>
      )}
    </Modal>
  );
};

export default SelfExclusionSuccessModal;
